import XLSX from 'xlsx'

export const exportExcel = (name = '报表') => {
  const html = `<html><head><meta charset='utf-8' /></head><body>
  ${document.getElementsByTagName('table')[0].outerHTML + document.getElementsByTagName('table')[1].outerHTML}
  </body></html>`
  const blob = new Blob([html], { type: 'application/vnd.ms-excel' })
  // 判断浏览器是否支持
  if ('download' in document.createElement('a')) {
    const eleLink = document.createElement('a')
    eleLink.href = URL.createObjectURL(blob)
    eleLink.download = this.tableName && this.tableTime
      ? `${this.tableName + this.tableTime}.xls`
      : `${name}.xls`
    eleLink.style.display = 'none'
    // 触发点击
    document.body.appendChild(eleLink)
    eleLink.click()
    document.body.removeChild(eleLink)
  } else {
    alert('浏览器不支持，请更换浏览器')
  }
}

// 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
export const sheet2blob = (sheet, sheetName) => {
  sheetName = sheetName || 'sheet1'
  const workbook = {
    SheetNames: [sheetName],
    Sheets: {}
  }
  workbook.Sheets[sheetName] = sheet // 生成excel的配置项
  const wopts = {
    bookType: 'xlsx', // 要生成的文件类型
    bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
    type: 'binary'
  }
  const wbout = XLSX.write(workbook, wopts)
  const blob = new Blob([s2ab(wbout)], {
    type: 'application/octet-stream'
  })
  // 字符串转ArrayBuffer
  function s2ab (s) {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF
    return buf
  }
  return blob
}

export const openDownloadDialog = (url, saveName) => {
  if (typeof url === 'object' && url instanceof Blob) {
    url = URL.createObjectURL(url) // 创建blob地址
  }
  const aLink = document.createElement('a')
  aLink.href = url
  aLink.download = saveName || '' // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
  let event
  if (window.MouseEvent) event = new MouseEvent('click')
  else {
    event = document.createEvent('MouseEvents')
    event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
  }
  aLink.dispatchEvent(event)
}
