<template>
  <el-dialog
    title="修改权限"
    :visible.sync="show"
    width="400px"
    :before-close="handleClose">
    <div>
      <p class="h5 mb-4">项目id: {{currUser.taskid}}</p>
      <p class="h5 mb-4">用户名: {{currUser.username}}</p>
      <el-select
        v-model="currval"
        multiple
        filterable
        allow-create
        default-first-option
        placeholder="请选择权限(可多选)"
        style="width:100%">
        <el-option
          v-for="(item, index) in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :class="showStr[index] === '1' ? '' : 'd-none'"
          :disabled="disabledStr(item.value)"
          >
        </el-option>
      </el-select>
      <p class="h5 my-4">组号:</p>
      <el-input type="number" v-model.number="currUser.teamid"></el-input>
      <p class="h5 my-4" v-if="layer !== 0">层级:</p>
      <el-select
        v-model="currUser.step"
        placeholder="请选择层级"
        style="width:100%"
        v-if="layer !== 0">
        <el-option
          v-for="(item, index) in (layer + 2)"
          :key="item"
          :label="index"
          :value="index">
        </el-option>
      </el-select>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { tuflagArr } from '../tuflag'
export default {
  props: {
    show: Boolean,
    layer: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      tuflag: 4095,
      currval: [],
      currUser: {},
      options: tuflagArr
    }
  },
  computed: {
    // 返回二进制字符串，长度不满12位在前补齐
    showStr () {
      const vm = this
      const BINARY = vm.tuflag.toString(2)
      const num = 12 - BINARY.length
      let result = ''
      if (num === 0) {
        result = BINARY
      } else {
        let zero = ''
        for (let i = 0; i < num; i++) {
          zero = zero + '0'
        }
        result = zero + BINARY
      }
      return result
    },
    // 计算tuflag
    tuflageResult () {
      return val => {
        let sum = 0
        val.forEach((value) => {
          sum = sum | value
        })
        return sum
      }
    }
  },
  mounted () {
  },
  methods: {
    load (currUser) {
      console.log('load')
      this.currUser = currUser
      let tuflag = this.currUser.puflag
      const ret = []
      let i = 0
      let cur = 1
      while (tuflag > 0) {
        if ((tuflag & 1) === 1) {
          ret[i++] = cur
        }
        cur = cur << 1
        tuflag = tuflag >> 1
      }
      this.currval = ret
    },
    submit () {
      if (this.currval.length < 1) {
        this.$message.error('权限不能为空')
      } else {
        this.$emit('changetuflag', this.tuflageResult(this.currval), this.currUser.teamid, this.currUser.step)
      }
    },
    handleClose () {
      console.log(this.currval)
      this.$emit('handleClose', false)
      this.currval = []
    },
    disabledStr (val) {
      let res = false
      const hasV = this.currval.find((val) => val === 1)
      // 点击其他隐藏拒绝
      if (val === 1 && !hasV && this.currval.length > 0) {
        res = true
      }
      // 点击拒绝隐藏其他
      if (val !== 1 && hasV) {
        res = true
      }
      return res
    }
  }
}
</script>
