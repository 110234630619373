<template>
  <div>
    <p class="h4 pt-4 pb-4">权限查询</p>
    <div class="mt-2 mb-4">
      <section style="display: inline-block">
        <el-input v-model="projectid" size="medium" placeholder="请输入projectid"></el-input>
      </section>
      <el-button type="primary" style="margin-left: 10px;" size="medium" round @click="getUserTuflag">查询</el-button>
    </div>
    <p class="mb-4">
      <span>项目类型：{{project.status4QC === 7001 ? '多层质检任务' : '普通任务'}}</span>
      <span class="ml-3" v-if="project.status4QC === 7001">质检层级：{{project.maxLayer}}</span>
    </p>
    <p class="h5" v-if="puflagData.length > 0">
      项目id: {{puflagData[0].projectid}}
      <button ype="button" class="btn btn-primary btn-sm ml-2" @click="exportXls()">导出表格</button>
    </p>
    <el-table
      class="mt-3 mb-5"
      :data="puflagData"
      border
      style="width: 100%">
      <el-table-column
        type="index"
        width="50">
      </el-table-column>
      <el-table-column
        prop="username"
        label="用户名"
        min-width="120">
      </el-table-column>
      <el-table-column
        label="权限"
        min-width="120">
        <template slot-scope="scope">
          <el-tag
            size="medium"
            type="primary"
            v-for="(item, index) in option"
            :key="item.value"
            class="mb-1 mr-1"
            :class="showStr(scope.row.puflag)[index] === '1' ? '' : 'd-none'"
            disable-transitions>{{item.label}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="teamid"
        label="团队ID"
        min-width="120">
      </el-table-column>
      <el-table-column
        prop="step"
        label="层级"
        min-width="120"
        v-if="project.status4QC === 7001">
      </el-table-column>
      <el-table-column
        label="操作"
        min-width="180">
        <template slot-scope="scope">
          <el-button size="mini" @click="edit(scope.row, scope.$index)">编辑</el-button>
          <el-button size="mini" type="danger" @click="delTuflag(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <editWindow ref="editWindow" :layer="project.maxLayer" :show="editShow" @changetuflag="changeflag" @handleClose="handleClose"></editWindow>
  </div>
</template>
<script>

import { sheet2blob, openDownloadDialog } from '@/assets/js/printXls'
import XLSX from 'xlsx'
import { tuflagArr } from './tuflag'
import editWindow from './components/edit-window'
import { projectData, getPuflag, changePuflag, delPuflag } from '@/api/limit'

export default {
  components: {
    editWindow
  },
  data () {
    return {
      editShow: false,
      currIndex: 0,
      currUser: {},
      option: tuflagArr,
      projectid: '',
      puflagData: [],
      project: {}
    }
  },
  computed: {
    getTuStr () {
      return (tuflag) => {
        tuflagArr.forEach((val, index) => {
          console.log(`${index}.${val.value}/${tuflag}`)
        })
        return tuflag
      }
    },
    showStr () {
      return val => {
        const BINARY = val.toString(2)
        const num = 12 - BINARY.length
        let result = ''
        if (num === 0) {
          result = BINARY
        } else {
          let zero = ''
          for (let i = 0; i < num; i++) {
            zero = zero + '0'
          }
          result = zero + BINARY
        }
        result = result.split('').reverse().join('')
        return result
      }
    }
  },
  methods: {
    handleClose (e) {
      this.editShow = e
    },
    /**
		 * 权限修改
		 * @param {number} puflag 修改后的puflag
     * @param {number} teamid 修改后的teamid
		 */
    changeflag (puflag, teamid, step) {
      // console.log(puflag, teamid, step)
      const vm = this
      vm.currUser.puflag = puflag
      vm.currUser.teamid = teamid
      vm.currUser.step = step
      // 交互
      const json = vm.currUser
      changePuflag(json).then(
        rsp => {
          if (rsp.code === 0) {
            // 成功改变table中的值
            vm.puflagData[vm.currIndex].puflag = puflag
            vm.puflagData[vm.currIndex].teamid = teamid
            vm.puflagData[vm.currIndex].step = step
            // 成功关闭窗口
            vm.editShow = false
            vm.$message({
              message: '修改成功',
              type: 'success'
            })
          } else {
            vm.$message({
              message: rsp.message,
              type: 'error'
            })
          }
        }
      ).catch(
        err => {
          vm.$message({
            message: '服务器或者网络异常',
            type: 'error'
          })
          console.log(err)
        }
      )
      vm.currUser = {} // 清空
    },
    /**
		 * 编辑按钮
		 * @param {object} row 修改的本条信息
     * @param {number} index 被修改的对象的索引
		 */
    edit (row, index) {
      this.editShow = true
      this.currUser = row
      this.currIndex = index
      this.$refs.editWindow.load(this.currUser)
    },
    exportXls () {
      const sheet = XLSX.utils.json_to_sheet(this.puflagData)
      openDownloadDialog(sheet2blob(sheet), `${this.projectid}权限表.xlsx`)
    },
    getUserTuflag () {
      const vm = this
      // 任务类型判断
      projectData(vm.projectid).then(
        res => {
          if (res.code === 0) {
            vm.project = res.project
            // 获取权限列表
            getPuflag({ projectid: parseInt(vm.projectid) }).then(
              res => {
                const { code, data } = res
                if (code === 0) {
                  console.log(data, 'data')
                  vm.puflagData = data.taskusers
                } else {
                  this.$notify({
                    title: '失败',
                    message: res.message,
                    type: 'error'
                  })
                }
              }
            ).catch(
              err => {
                console.log(err)
              }
            )
          } else {
            this.$notify({
              title: '失败',
              message: res.message,
              type: 'error'
            })
          }
        }
      )
    },
    success (msg) {
      this.$notify({
        title: '成功',
        message: msg,
        type: 'success'
      })
    },
    error (msg) {
      this.$notify({
        title: '失败',
        message: msg,
        type: 'error'
      })
    },
    delTuflag (index, row) {
      const vm = this
      console.log(row)
      this.$confirm('此操作将删除任务：' + row.projectid + '中用户：' + row.username + '的权限,是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delPuflag({
            projectid: row.projectid,
            username: row.username
          }).then(
            rsp => {
              if (rsp.code === 0) {
                vm.success('删除成功')
                vm.puflagData.splice(index, 1)
              } else {
                vm.error(rsp.message)
                // row.status = '删除失败'
              }
            }
          ).catch(
            err => {
              console.log(err)
            }
          )
        })
        .catch(error => {
          vm.error(error.message)
        })
    }
  }
}
</script>
