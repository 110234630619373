export const tuflagArr = [
  {
    value: 1 << 0,
    label: '拒绝'
  }, {
    value: 1 << 1,
    label: '做任务'
  }, {
    value: 1 << 2,
    label: '查看自己的任务'
  }, {
    value: 1 << 3,
    label: '修改自己的任务'
  }, {
    value: 1 << 4,
    label: '查看组内的任务'
  }, {
    value: 1 << 5,
    label: '修改组内的任务'
  }, {
    value: 1 << 6,
    label: '质检组内的任务'
  }, {
    value: 1 << 7,
    label: '组内角色管理'
  }, {
    value: 1 << 8,
    label: '查看所有的任务'
  }, {
    value: 1 << 9,
    label: '修改所有的任务'
  }, {
    value: 1 << 10,
    label: '质检所有的任务'
  }, {
    value: 1 << 11,
    label: '角色管理'
  }
]
